import * as Yup from 'yup';
import { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
// import { useNavigate } from 'react-router-dom';
// material
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
// Third party
import toast, { Toaster } from 'react-hot-toast';
import io from 'socket.io-client';
import { useDispatch } from 'react-redux';
// import { setAuth } from '../../store/reducer/auth';
import { useNavigate } from 'react-router-dom';
import { baseURL } from '../../utils/axios';
import { useProfile } from '../../hooks';
// import socket from '../../utils/socket';
// Services
import APIService from '../../service';
// component
import Iconify from '../Iconify';

// ----------------------------------------------------------------------

export default function LoginForm(props) {
  const { mutate } = props;
  const [loading, setLoading] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let socketClient;

  const { data, loading: dataLoading, mutate: profileMutate } = useProfile();
  // const navigate = useNavigate();

  const LoginSchema = Yup.object().shape({
    emailAddress: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const sendOTPSilently = async (email) => {
    try {
      await APIService.post('/auth/send-otp', { emailAddress: email });
      // console.log("SILENT OTP SEND RESPONSE ::: ", response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      emailAddress: '',
      password: '',
    },
    validationSchema: LoginSchema,
    onSubmit: async () => {
      try {
        setLoading(true);
        socketClient = io(baseURL);
        const response = await APIService.post('/auth/login', values);

        setLoading(false);
        toast.success(`${response?.data?.message || "Login successful"}`)

        if (data?.accountStatus === 'frozen') {
          localStorage.removeItem('accessToken');
          localStorage.removeItem('refreshToken');
          return 'Sorry. your account is frozen';
        }

        localStorage.setItem('accessToken', response?.data?.accessToken);
        localStorage.setItem('refreshToken', response?.data?.refreshToken);
        mutate();
        setTimeout(() => {
          mutate();
        }, 4000);
      } catch (err) {
        setLoading(false);
        toast.error(`${ err?.response?.data?.message || err?.message || 'Something went wrong, try again.'}`)
        if (err?.response?.data?.message || err?.message === 'Account is not yet verified!') {
          // Send OTP to this email first
          await sendOTPSilently(values.emailAddress);
          navigate('/verify-otp', {
            state: {
              emailAddress: values?.emailAddress,
              accessToken: err?.response?.data?.accessToken || err?.accessToken,
              refreshToken: err?.response?.data?.accessToken || err?.refreshToken
            },
            replace: true,
          });
        }
      }

    },
  });

  const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit} style={{ width: '100%' }}>
        <Stack spacing={2} sx={{ marginBottom: 2 }}>
          <TextField
            fullWidth
            autoComplete="email-address"
            type="email"
            label="Email address"
            {...getFieldProps('emailAddress')}
            error={Boolean(touched.emailAddress && errors.emailAddress)}
            helperText={touched.emailAddress && errors.emailAddress}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}>
          Login
        </LoadingButton>
      </Form>
      <Toaster />
    </FormikProvider>
  );
}
